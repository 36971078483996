import React, { useState } from "react"
import { Modal, Button } from "react-bootstrap"
import HubspotForm from "react-hubspot-form"
import useLiveControl from "../controlled/useLiveControl"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Feedback = () => {
  const [show, setShow] = useState(false)
  const [message] = useState(false)
  const handleClose = () => setShow(false)

  const { isStaging } = useLiveControl()

  return (
    <Layout>
      <Seo
        title={"Feedback"}
        description={
          "If you have a question or feedback for the EnerBank USA team, we'd love to hear it! Fill out the feedback form here."
        }
      />

      <section className={`${isStaging ? "bg-light-gray py-5" : "" }`}>
        <div className="container">
          <div className="col-lg-10 mx-auto">
            <h1 className="text-center mb-5">Comments and Questions</h1>
            <p className="mb-0 text-blue font-weight-bold">
              Question about a loan? Have a comment? We want to hear from you.
            </p>
            <p className="small text-warning mb-5">*indicates required fields</p>

            <HubspotForm
              portalId="381510"
              formId="fe8cd589-e097-42d5-a9aa-4c65409539f3"
              onSubmit={() => this.handleFormSubmit}
              onReady={form => console.log("Form ready!")}
              loading={<div>Loading...</div>}
            />
          </div>
        </div>
      </section>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body
          className="p-4 mt-5 text-center"
          dangerouslySetInnerHTML={{ __html: message }}
        />
        <Modal.Footer className="border-0">
          <Button variant="primary mb-3 mx-auto" onClick={handleClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  )
}

export default Feedback

// export const query = graphql`
//     query($databaseId: ID!) {
//         wpgraphql {
//             page(id: $databaseId, idType: DATABASE_ID) {
//                 title
//                 date
//                 content(format: RENDERED)
//                 featuredImage {
//                     altText
//                     title(format: RENDERED)
//                     mediaItemUrl
//                     slug
//                 }
//             }
//         }
//     }
// `
